<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>
        <v-card-text class="row content mx-0">
        
            <v-row align-content="center" justify="center">
                <v-col cols="10" md="8" lg="6">

                    <v-card-title class="headline pl-0" style="word-break: break-word;">Welcome to the LFA rental shop</v-card-title>
                    <v-card-subtitle class="pl-0">Please select an option below to continue</v-card-subtitle>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('rent')">
                                <v-card-text>
                                    <div class="title">I want to rent</div>
                                    <div>Browse our packages and selecte the perfect outfit for you.</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('event')">
                                <v-card-text>
                                    <div class="title">I'm part of an event</div>
                                    <div>I am ready to make it official</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card flat color="grey lighten-4" class="hover" @click="toPath('event-status')">
                                <v-card-text>
                                    <div class="title">My event</div>
                                    <div>Check the status of your event</div>
                                </v-card-text>        
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-btn absolute bottom left depressed x-large color="error" @click="restart" :icon="$vuetify.breakpoint.smAndDown">
                <v-icon :left="!$vuetify.breakpoint.smAndDown">mdi-restart</v-icon> 
                <span v-if="!$vuetify.breakpoint.smAndDown">Restart</span>
            </v-btn>

        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { API } from '@/inc/api';

export default {
    mounted() {
        this.validateStore();
    },
    methods: {
        ...mapActions({
            setPath     : 'setPath',
            setToken    : 'setToken',
        }),
        async validateStore() {
            try {
                if( await this.isTokenValid() ) {
                    return;
                }

                // if no token is found, validate the querystring
                let key = this.getURLByName('s');
                if( key == undefined || key == null || key.length == 0 || key == 'null' ) {
                    // unauthorized
                    this.$router.push('/unauthorized');
                    return;
                }

            
                let api = new API();
                let res = await api.get(`/kiosk/access/${key}`);

                if( res.data.status == false ) {
                    this.$router.push('/unauthorized');
                    return;
                }

                localStorage.setItem('lfa-kiosk', res.data.data );

                // set axios Authorization key
                this.setAuthKey();
                window.history.pushState({}, document.title, "/");
            }
            catch(error) {
                this.mxShowMessage( 'Error', error );
            }
        },
        async setAuthKey() {
            let token = localStorage.getItem('lfa-kiosk');
            if( token != undefined & token != null && token.length > 0 ) {

                try {
                    var jwt = require('jsonwebtoken');
                    await jwt.verify(token, '!f@-k105k', { algorithms: ['HS256','HS384'] });
                    this.setToken( token );
                }
                catch(error) {
                    console.log(error);
                    localStorage.removeItem('lfa-kiosk');
                    return false;
                }
            }

            return false;
        },
        getURLByName(name, url = window.location.href) {
            name = name.replace(/[[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        async isTokenValid() {
            let token = localStorage.getItem('lfa-kiosk');
            let valid = false;
            
            if( token != undefined & token != null && token.length > 0 ) {
                var jwt = require('jsonwebtoken');
                
                try {
                    await jwt.verify(token, '!f@-k105k');
                
                    // if there are no errors continue to the kiosk
                    valid = true;
                }
                catch(error) {
                    valid = false;
                    localStorage.removeItem('lfa-kiosk');
                    this.setAuthKey();
                }
            }

            return valid;
        },
        toPath( path ) {
            this.setPath( path );

            if( path == 'rent' )
                this.$router.push('/use-date');
            else if( path == 'event' )
                this.$router.push('/event');
            else 
                this.$router.push('/event-status');
        }
    }
}
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  flex-flow: column;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box .row.content {
  flex: 1 1 auto;
}

.box .row.footer {
  flex: 0 1 40px;
}

.fit {
  background-color: #ececec;
}

.hover:hover {
    background-color: #e7e7e7 !important;
}
</style>